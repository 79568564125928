import React from 'react'
import PropTypes from 'prop-types'

import Layout from 'Layout'
import { HTMLContent } from 'Content'
import SEO from 'SEO'
import View from './EpisodeView'

const Episode = ({ episode }) => {
  const { frontmatter, html, fields, excerpt } = episode
  const { slug } = fields
  const { title, audioUrl, show } = frontmatter

  return (
    <Layout>
      <SEO
        title={`${title} by ${show.frontmatter.title}`}
        description={excerpt}
        slug={slug}
        image={show.frontmatter.artwork}
      />
      <View
        title={title}
        audioUrl={audioUrl}
        show={show}
        content={html}
        contentComponent={HTMLContent}
        slug={slug}
      />
    </Layout>
  )
}

Episode.propTypes = {
  episode: PropTypes.object.isRequired
}

export default Episode
