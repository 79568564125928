import React from 'react'
import { Link } from 'gatsby'

import Image from 'Image'
import SocialShare from 'SocialShare'

import PlayButton from 'AudioPlayer/AudioPlayerUpdateTrackButton'
import Feeds from 'Show/ShowFeeds'

const EpisodeHeader = ({ title, show, track, slug }) => (
  <header
    className='c-show-hero'
  >
    <div
      className='o-container--thin'
    >
      <div
        className='c-show-hero__grid c-show-hero__content'
      >
        <figure
          className='c-show-hero__figure u-img-full'
        >
          <Image
            image={show.artwork}
            width={684}
            sizes={[
              `${Image.breakpoint('md')} calc(100vw - 54px)`,
              `${Image.breakpoint('container')} calc((100vw - 54px) / 3)`,
              '420px'
            ]}
            alt={`The artwork for the ${show.title} podcast.`}
          />
        </figure>
        <div
          className='c-show-hero__content'
        >
          <div
            className='u-margin-bottom u-no-last-margin'
          >
            <h2
              className='e-h3 u-margin-bottom-small'
            >
              {title}
            </h2>
            <h3
              className='e-h5 u-fw-normal'
            >
              by{` `}
              <Link
                to={show.slug}
                className='u-color-white o-action-link'
              >
                {show.title}
              </Link>
            </h3>
            <div
              className='c-show-hero__share u-margin-bottom'
            >
              <SocialShare
                title={title}
                slug={slug}
                shape='oval'
              />
            </div>
          </div>
          <div
            className='u-margin-bottom-large'
          >
            <Feeds
              feeds={show.feeds}
              rssFeedUrl={show.rssFeedUrl}
              trackUrl={track.url}
            />
          </div>
          <PlayButton
            track={track}
            parentClassName='o-action-link__parent'
            iconClassName='c-show-hero__play-button u-color-grey'
          >
            <span
              className='u-margin-left-small o-action-link u-align-text-top u-fs-14'
            >
              Play episode
            </span>
          </PlayButton>
        </div>
      </div>
    </div>
    <div
      className='c-show-hero__image'
      style={{ backgroundColor: `${show.hex}` }}
    />
  </header>
)

export default EpisodeHeader
