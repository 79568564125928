import React from 'react'
import PropTypes from 'prop-types'

import Content from 'Content'
import AudioPlayerUpdateTrackButton from 'AudioPlayer/AudioPlayerUpdateTrackButton'

import Header from './EpisodeHeader'

const EpisodeView = ({ title, slug, audioUrl, show, content, contentComponent }) => {
  const EpisodeContent = contentComponent || Content

  const track = {
    url: audioUrl,
    name: title,
    show: Object.assign(show.frontmatter, show.fields),
    slug: slug
  }

  return (
    <article>
      <Header
        title={title}
        artwork={show.frontmatter.artwork}
        show={Object.assign(show.frontmatter, show.fields)}
        track={track}
        slug={slug}
      />
      <div
        className='o-container--prose'
      >
        <aside className='u-margin-top u-margin-bottom u-margin-top-huge@md u-margin-bottom-large@md u-bgcolor-concrete u-color-smoke u-padding-left u-padding-right u-padding-top-small u-padding-bottom-small'>
          <AudioPlayerUpdateTrackButton
            track={track}
            parentClassName='c-episode-player o-action-link__parent u-margin-right-small u-align-middle'
            iconClassName='c-episode-player__btn'
          />
          <div
            className='u-dis-ib u-width-4/5 u-align-middle u-no-last-margin'
          >
            <h3
              className='e-h5 u-margin-bottom-none'
              style={{
                color: `${show.frontmatter.hex}`
              }}
            >
              {show.frontmatter.title}
            </h3>
            <h4 className='e-h5 u-fw-normal'>{title}</h4>
          </div>
        </aside>
        <EpisodeContent
          content={content}
          className='u-margin-bottom u-margin-bottom-huge@md u-color-grey'
        />
      </div>
    </article>
  )
}

EpisodeView.propTypes = {
  title: PropTypes.string.isRequired,
  audioUrl: PropTypes.string.isRequired,
  show: PropTypes.object.isRequired,
  content: PropTypes.node,
  contentComponent: PropTypes.func
}

export default EpisodeView
