import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Episode from 'Episode'

const EpisodeTemplate = ({ data }) => {
  const { markdownRemark: episode } = data

  return (
    <Episode
      episode={episode}
    />
  )
}

EpisodeTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export default EpisodeTemplate

export const EpisodeQuery = graphql`
  query (
    $id: String!
  ) {
    markdownRemark(
      id: {
        eq: $id
      }
    ) {
      id
      html
      excerpt
      fields {
        slug
      }
      frontmatter {
        title
        audioUrl
        show {
          fields {
            slug
          }
          frontmatter {
            title
            hex
            artwork
            rssFeedUrl
            feeds {
              type
              url
            }
          }
        }
      }
    }
    otherEpisodes: allMarkdownRemark(
      filter: {
        id: {
          ne: $id
        }
        frontmatter: {
          showInRecentEpisodes: {
            eq: true
          }
          templateKey: {
            regex: "/EpisodeTemplate/"
          }
        }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            audioUrl
            show {
              frontmatter {
                title
                artwork
              }
            }
          }
        }
      }
    }
  }
`
